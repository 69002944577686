var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm mới link hỗ trợ",
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "100px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên" },
                model: {
                  value: _vm.dataInput.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "name", $$v)
                  },
                  expression: "dataInput.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Link", prop: "link" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập link" },
                model: {
                  value: _vm.dataInput.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "link", $$v)
                  },
                  expression: "dataInput.link",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Đối tượng" } },
            [
              _vm._v("\n      Nhà trường\n      "),
              _c("el-checkbox", {
                model: {
                  value: _vm.dataInput.plusStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "plusStatus", $$v)
                  },
                  expression: "dataInput.plusStatus",
                },
              }),
              _vm._v("\n      Giáo viên\n      "),
              _c("el-checkbox", {
                model: {
                  value: _vm.dataInput.teacherStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "teacherStatus", $$v)
                  },
                  expression: "dataInput.teacherStatus",
                },
              }),
              _vm._v("\n      Phụ huynh\n      "),
              _c("el-checkbox", {
                model: {
                  value: _vm.dataInput.parentStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "parentStatus", $$v)
                  },
                  expression: "dataInput.parentStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Link mặc định" } },
            [
              _c("el-checkbox", {
                on: { change: _vm.checkLinkDefault },
                model: {
                  value: _vm.linkDefaultCheckBox,
                  callback: function ($$v) {
                    _vm.linkDefaultCheckBox = $$v
                  },
                  expression: "linkDefaultCheckBox",
                },
              }),
            ],
            1
          ),
          !_vm.linkDefaultCheckBox
            ? _c(
                "el-form-item",
                { attrs: { label: "Dashboard" } },
                [
                  _c("el-checkbox", {
                    on: { change: _vm.checkLinkType },
                    model: {
                      value: _vm.linkTypeCheckBox,
                      callback: function ($$v) {
                        _vm.linkTypeCheckBox = $$v
                      },
                      expression: "linkTypeCheckBox",
                    },
                  }),
                  _vm.linkTypeCheckBox
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "300px",
                            "margin-left": "15px",
                          },
                          attrs: {
                            filterable: "",
                            placeholder: "Chọn trường",
                            clearable: "",
                          },
                          model: {
                            value: _vm.dataInput.idSchool,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "idSchool", $$v)
                            },
                            expression: "dataInput.idSchool",
                          },
                        },
                        _vm._l(_vm.schoolListSearch, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              value: item.id,
                              label: item.id + " - " + item.schoolName,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.responseList,
                "highlight-current-row": "",
                "header-cell-style": _vm.$funcCommon.tableHeaderColorFunction,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Tên", width: "240" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.name,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "name", $$v)
                            },
                            expression: "scope.row.name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Link", prop: "link" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.link,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "link", $$v)
                            },
                            expression: "scope.row.link",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Nhà trường", align: "center", width: "95" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          model: {
                            value: scope.row.plusStatus,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "plusStatus", $$v)
                            },
                            expression: "scope.row.plusStatus",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Giáo viên", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          model: {
                            value: scope.row.teacherStatus,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "teacherStatus", $$v)
                            },
                            expression: "scope.row.teacherStatus",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Phụ huynh", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          model: {
                            value: scope.row.parentStatus,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "parentStatus", $$v)
                            },
                            expression: "scope.row.parentStatus",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Mặc định hệ thống",
                  align: "center",
                  width: "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: { "aria-readonly": "true" },
                          model: {
                            value: scope.row.linkType === "linkDefault",
                            callback: function ($$v) {
                              _vm.$set(
                                scope.row,
                                "linkType === 'linkDefault'",
                                $$v
                              )
                            },
                            expression: "scope.row.linkType === 'linkDefault'",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Dashboard", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: { "aria-readonly": "true" },
                          model: {
                            value:
                              scope.row.linkType === "dashboard" ||
                              scope.row.linkType === "linkDefault",
                            callback: function ($$v) {
                              _vm.$set(
                                scope.row.linkType === "dashboard" || scope.row,
                                "linkType === 'linkDefault'",
                                $$v
                              )
                            },
                            expression:
                              "scope.row.linkType === 'dashboard'|| scope.row.linkType === 'linkDefault'",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  width: "240px",
                  prop: "idSchool",
                  label: "Tên trường",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.filterDataMethod(scope.row.idSchool))
                          ),
                        ]),
                        _c("br"),
                        _vm.filterDataMethod(scope.row.idSchool) !==
                          "Toàn hệ thống" &&
                        _vm.filterDataMethod(scope.row.idSchool) !==
                          "Link dành cho Dashboard"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "warning" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdateDefault(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Đặt mặc định\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "150", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("Lưu\n          ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("Xóa\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "button-click row-data",
          staticStyle: { "margin-top": "5px" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "button-over",
              attrs: { type: "success" },
              on: {
                click: function ($event) {
                  return _vm.createSupportDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-plus" }),
              _vm._v("\n      Thêm mới\n    "),
            ]
          ),
        ],
        1
      ),
      _c("SupportCreateDialog", {
        ref: "SupportCreateDialog",
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeSupportDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }